<template>
  <div>
    <p class="is-size-1">404 Page Not Found</p>
    <p>The page you requested could not be found. Please go back to the homepage or login/register.</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
